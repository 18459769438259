@use "sass:math";
@import "compass-mixins";
html {
  background: no-repeat fixed center rgb(34, 19, 122);
  background-image: url('static/images/bg_salesforce_kinetics_system.jpg');
  background-size: cover;
}

body {
  // background: rgb(5, 5, 15);
  overflow: hidden;
}

.page-container {
  height: 100vh;
  width: 100vw;
}

.dot {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 0;
}

@for $n from 0 through 50 {
  $size: random(40);
  $hue: 180 + random(360);

  .dot:nth-child(#{$n}) {
    animation: anim-#{$n} 15s+random(35) infinite alternate math.div(random(10), 10) * 1s;
    background: radial-gradient(hsl($hue, 95, 65),
        hsl($hue + 60, 95, 65) 49%,
        transparent 51%);
    border: 2px solid hsl($hue, 80, 85);
    transform: translate3d(random(100) + vw, random(100) + vh, 0);
    opacity: math.div(random(10), 10);
    width: $size + px;
    height: $size + px;
  }

  @keyframes anim-#{$n} {
    33% {
      transform: translate3d(random(100) + vw, random(100) + vh, 0);
      opacity: 0;
    }

    66% {
      transform: translate3d(random(100) + vw, random(100) + vh, 0);
    }

    100% {
      transform: translate3d(random(100) + vw, random(100) + vh, 0);
    }
  }

}