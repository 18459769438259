html {
  background: #22137a url("bg_salesforce_kinetics_system.f862b126.jpg") center / cover no-repeat fixed;
}

body {
  overflow: hidden;
}

.page-container {
  height: 100vh;
  width: 100vw;
}

.dot {
  transform-origin: 0 0;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.dot:nth-child(0) {
  opacity: .3;
  width: 40px;
  height: 40px;
  background: radial-gradient(#c5fb51, #51fb87 49%, #0000 51%);
  border: 2px solid #e4f7ba;
  animation: 25s .5s infinite alternate anim-0;
  transform: translate3d(13vw, 23vh, 0);
}

@keyframes anim-0 {
  33% {
    opacity: 0;
    transform: translate3d(54vw, 47vh, 0);
  }

  66% {
    transform: translate3d(83vw, 35vh, 0);
  }

  100% {
    transform: translate3d(51vw, 21vh, 0);
  }
}

.dot:nth-child(1) {
  opacity: .1;
  width: 24px;
  height: 24px;
  background: radial-gradient(#5751fb, #fb51f5 49%, #0000 51%);
  border: 2px solid #bcbaf7;
  animation: 26s 1s infinite alternate anim-1;
  transform: translate3d(82vw, 76vh, 0);
}

@keyframes anim-1 {
  33% {
    opacity: 0;
    transform: translate3d(1vw, 11vh, 0);
  }

  66% {
    transform: translate3d(78vw, 94vh, 0);
  }

  100% {
    transform: translate3d(48vw, 91vh, 0);
  }
}

.dot:nth-child(2) {
  opacity: .9;
  width: 10px;
  height: 10px;
  background: radial-gradient(#51f2fb, #5951fb 49%, #0000 51%);
  border: 2px solid #baf4f7;
  animation: 41s .6s infinite alternate anim-2;
  transform: translate3d(72vw, 37vh, 0);
}

@keyframes anim-2 {
  33% {
    opacity: 0;
    transform: translate3d(65vw, 69vh, 0);
  }

  66% {
    transform: translate3d(16vw, 8vh, 0);
  }

  100% {
    transform: translate3d(28vw, 96vh, 0);
  }
}

.dot:nth-child(3) {
  opacity: .9;
  width: 13px;
  height: 13px;
  background: radial-gradient(#51cdfb, #7e51fb 49%, #0000 51%);
  border: 2px solid #bae7f7;
  animation: 36s .4s infinite alternate anim-3;
  transform: translate3d(29vw, 14vh, 0);
}

@keyframes anim-3 {
  33% {
    opacity: 0;
    transform: translate3d(85vw, 100vh, 0);
  }

  66% {
    transform: translate3d(30vw, 3vh, 0);
  }

  100% {
    transform: translate3d(85vw, 6vh, 0);
  }
}

.dot:nth-child(4) {
  opacity: .2;
  width: 6px;
  height: 6px;
  background: radial-gradient(#51cdfb, #7e51fb 49%, #0000 51%);
  border: 2px solid #bae7f7;
  animation: 40s .5s infinite alternate anim-4;
  transform: translate3d(91vw, 46vh, 0);
}

@keyframes anim-4 {
  33% {
    opacity: 0;
    transform: translate3d(27vw, 48vh, 0);
  }

  66% {
    transform: translate3d(60vw, 55vh, 0);
  }

  100% {
    transform: translate3d(98vw, 55vh, 0);
  }
}

.dot:nth-child(5) {
  opacity: .9;
  width: 40px;
  height: 40px;
  background: radial-gradient(#51fb59, #51f2fb 49%, #0000 51%);
  border: 2px solid #baf7bd;
  animation: 45s .1s infinite alternate anim-5;
  transform: translate3d(54vw, 26vh, 0);
}

@keyframes anim-5 {
  33% {
    opacity: 0;
    transform: translate3d(70vw, 1vh, 0);
  }

  66% {
    transform: translate3d(47vw, 18vh, 0);
  }

  100% {
    transform: translate3d(17vw, 85vh, 0);
  }
}

.dot:nth-child(6) {
  opacity: .1;
  width: 6px;
  height: 6px;
  background: radial-gradient(#51fb59, #51f2fb 49%, #0000 51%);
  border: 2px solid #baf7bd;
  animation: 42s .2s infinite alternate anim-6;
  transform: translate3d(16vw, 89vh, 0);
}

@keyframes anim-6 {
  33% {
    opacity: 0;
    transform: translate3d(40vw, 70vh, 0);
  }

  66% {
    transform: translate3d(9vw, 44vh, 0);
  }

  100% {
    transform: translate3d(80vw, 89vh, 0);
  }
}

.dot:nth-child(7) {
  opacity: .6;
  width: 24px;
  height: 24px;
  background: radial-gradient(#515ffb, #ec51fb 49%, #0000 51%);
  border: 2px solid #babff7;
  animation: 47s .9s infinite alternate anim-7;
  transform: translate3d(21vw, 1vh, 0);
}

@keyframes anim-7 {
  33% {
    opacity: 0;
    transform: translate3d(16vw, 5vh, 0);
  }

  66% {
    transform: translate3d(4vw, 62vh, 0);
  }

  100% {
    transform: translate3d(88vw, 7vh, 0);
  }
}

.dot:nth-child(8) {
  opacity: .1;
  width: 28px;
  height: 28px;
  background: radial-gradient(#d3fb51, #51fb79 49%, #0000 51%);
  border: 2px solid #e9f7ba;
  animation: 50s 1s infinite alternate anim-8;
  transform: translate3d(51vw, 31vh, 0);
}

@keyframes anim-8 {
  33% {
    opacity: 0;
    transform: translate3d(65vw, 83vh, 0);
  }

  66% {
    transform: translate3d(23vw, 49vh, 0);
  }

  100% {
    transform: translate3d(74vw, 60vh, 0);
  }
}

.dot:nth-child(9) {
  opacity: .3;
  width: 40px;
  height: 40px;
  background: radial-gradient(#fbd351, #79fb51 49%, #0000 51%);
  border: 2px solid #f7e9ba;
  animation: 19s .5s infinite alternate anim-9;
  transform: translate3d(3vw, 31vh, 0);
}

@keyframes anim-9 {
  33% {
    opacity: 0;
    transform: translate3d(11vw, 65vh, 0);
  }

  66% {
    transform: translate3d(26vw, 46vh, 0);
  }

  100% {
    transform: translate3d(77vw, 91vh, 0);
  }
}

.dot:nth-child(10) {
  opacity: .3;
  width: 17px;
  height: 17px;
  background: radial-gradient(#51fbb4, #5198fb 49%, #0000 51%);
  border: 2px solid #baf7de;
  animation: 19s .6s infinite alternate anim-10;
  transform: translate3d(1vw, 29vh, 0);
}

@keyframes anim-10 {
  33% {
    opacity: 0;
    transform: translate3d(87vw, 58vh, 0);
  }

  66% {
    transform: translate3d(96vw, 21vh, 0);
  }

  100% {
    transform: translate3d(97vw, 74vh, 0);
  }
}

.dot:nth-child(11) {
  opacity: .3;
  width: 2px;
  height: 2px;
  background: radial-gradient(#8151fb, #fb51ca 49%, #0000 51%);
  border: 2px solid #cbbaf7;
  animation: 37s .4s infinite alternate anim-11;
  transform: translate3d(49vw, 34vh, 0);
}

@keyframes anim-11 {
  33% {
    opacity: 0;
    transform: translate3d(6vw, 69vh, 0);
  }

  66% {
    transform: translate3d(24vw, 37vh, 0);
  }

  100% {
    transform: translate3d(23vw, 80vh, 0);
  }
}

.dot:nth-child(12) {
  opacity: 1;
  width: 39px;
  height: 39px;
  background: radial-gradient(#fb5951, #f2fb51 49%, #0000 51%);
  border: 2px solid #f7bdba;
  animation: 34s .9s infinite alternate anim-12;
  transform: translate3d(25vw, 44vh, 0);
}

@keyframes anim-12 {
  33% {
    opacity: 0;
    transform: translate3d(90vw, 71vh, 0);
  }

  66% {
    transform: translate3d(15vw, 30vh, 0);
  }

  100% {
    transform: translate3d(95vw, 59vh, 0);
  }
}

.dot:nth-child(13) {
  opacity: .2;
  width: 22px;
  height: 22px;
  background: radial-gradient(#e151fb, #fb516a 49%, #0000 51%);
  border: 2px solid #eebaf7;
  animation: 42s .3s infinite alternate anim-13;
  transform: translate3d(93vw, 23vh, 0);
}

@keyframes anim-13 {
  33% {
    opacity: 0;
    transform: translate3d(77vw, 13vh, 0);
  }

  66% {
    transform: translate3d(5vw, 14vh, 0);
  }

  100% {
    transform: translate3d(66vw, 96vh, 0);
  }
}

.dot:nth-child(14) {
  opacity: .1;
  width: 32px;
  height: 32px;
  background: radial-gradient(#fb5451, #f8fb51 49%, #0000 51%);
  border: 2px solid #f7bbba;
  animation: 45s .1s infinite alternate anim-14;
  transform: translate3d(32vw, 61vh, 0);
}

@keyframes anim-14 {
  33% {
    opacity: 0;
    transform: translate3d(65vw, 22vh, 0);
  }

  66% {
    transform: translate3d(52vw, 69vh, 0);
  }

  100% {
    transform: translate3d(11vw, 64vh, 0);
  }
}

.dot:nth-child(15) {
  opacity: .2;
  width: 33px;
  height: 33px;
  background: radial-gradient(#51fbe1, #516afb 49%, #0000 51%);
  border: 2px solid #baf7ee;
  animation: 17s .7s infinite alternate anim-15;
  transform: translate3d(36vw, 20vh, 0);
}

@keyframes anim-15 {
  33% {
    opacity: 0;
    transform: translate3d(57vw, 68vh, 0);
  }

  66% {
    transform: translate3d(80vw, 48vh, 0);
  }

  100% {
    transform: translate3d(5vw, 75vh, 0);
  }
}

.dot:nth-child(16) {
  opacity: .2;
  width: 27px;
  height: 27px;
  background: radial-gradient(#fb51de, #fb6d51 49%, #0000 51%);
  border: 2px solid #f7baed;
  animation: 35s .4s infinite alternate anim-16;
  transform: translate3d(76vw, 89vh, 0);
}

@keyframes anim-16 {
  33% {
    opacity: 0;
    transform: translate3d(83vw, 85vh, 0);
  }

  66% {
    transform: translate3d(94vw, 23vh, 0);
  }

  100% {
    transform: translate3d(20vw, 74vh, 0);
  }
}

.dot:nth-child(17) {
  opacity: .9;
  width: 15px;
  height: 15px;
  background: radial-gradient(#c8fb51, #51fb84 49%, #0000 51%);
  border: 2px solid #e5f7ba;
  animation: 48s .9s infinite alternate anim-17;
  transform: translate3d(86vw, 39vh, 0);
}

@keyframes anim-17 {
  33% {
    opacity: 0;
    transform: translate3d(32vw, 66vh, 0);
  }

  66% {
    transform: translate3d(29vw, 74vh, 0);
  }

  100% {
    transform: translate3d(22vw, 18vh, 0);
  }
}

.dot:nth-child(18) {
  opacity: 1;
  width: 6px;
  height: 6px;
  background: radial-gradient(#5198fb, #b451fb 49%, #0000 51%);
  border: 2px solid #bad4f7;
  animation: 38s .2s infinite alternate anim-18;
  transform: translate3d(35vw, 63vh, 0);
}

@keyframes anim-18 {
  33% {
    opacity: 0;
    transform: translate3d(85vw, 86vh, 0);
  }

  66% {
    transform: translate3d(4vw, 24vh, 0);
  }

  100% {
    transform: translate3d(17vw, 47vh, 0);
  }
}

.dot:nth-child(19) {
  opacity: 1;
  width: 28px;
  height: 28px;
  background: radial-gradient(#fb516d, #fbde51 49%, #0000 51%);
  border: 2px solid #f7bac4;
  animation: 25s .5s infinite alternate anim-19;
  transform: translate3d(15vw, 58vh, 0);
}

@keyframes anim-19 {
  33% {
    opacity: 0;
    transform: translate3d(40vw, 12vh, 0);
  }

  66% {
    transform: translate3d(55vw, 2vh, 0);
  }

  100% {
    transform: translate3d(54vw, 96vh, 0);
  }
}

.dot:nth-child(20) {
  opacity: .2;
  width: 28px;
  height: 28px;
  background: radial-gradient(#8151fb, #fb51ca 49%, #0000 51%);
  border: 2px solid #cbbaf7;
  animation: 20s .8s infinite alternate anim-20;
  transform: translate3d(7vw, 78vh, 0);
}

@keyframes anim-20 {
  33% {
    opacity: 0;
    transform: translate3d(95vw, 40vh, 0);
  }

  66% {
    transform: translate3d(39vw, 44vh, 0);
  }

  100% {
    transform: translate3d(62vw, 16vh, 0);
  }
}

.dot:nth-child(21) {
  opacity: .5;
  width: 23px;
  height: 23px;
  background: radial-gradient(#a9fb51, #51fba3 49%, #0000 51%);
  border: 2px solid #daf7ba;
  animation: 46s .2s infinite alternate anim-21;
  transform: translate3d(44vw, 39vh, 0);
}

@keyframes anim-21 {
  33% {
    opacity: 0;
    transform: translate3d(77vw, 72vh, 0);
  }

  66% {
    transform: translate3d(85vw, 25vh, 0);
  }

  100% {
    transform: translate3d(6vw, 90vh, 0);
  }
}

.dot:nth-child(22) {
  opacity: .7;
  width: 11px;
  height: 11px;
  background: radial-gradient(#fb51a6, #fba651 49%, #0000 51%);
  border: 2px solid #f7bad9;
  animation: 28s .1s infinite alternate anim-22;
  transform: translate3d(34vw, 82vh, 0);
}

@keyframes anim-22 {
  33% {
    opacity: 0;
    transform: translate3d(62vw, 78vh, 0);
  }

  66% {
    transform: translate3d(19vw, 33vh, 0);
  }

  100% {
    transform: translate3d(88vw, 89vh, 0);
  }
}

.dot:nth-child(23) {
  opacity: .9;
  width: 39px;
  height: 39px;
  background: radial-gradient(#fb5187, #fbc551 49%, #0000 51%);
  border: 2px solid #f7bace;
  animation: 27s .8s infinite alternate anim-23;
  transform: translate3d(88vw, 77vh, 0);
}

@keyframes anim-23 {
  33% {
    opacity: 0;
    transform: translate3d(71vw, 64vh, 0);
  }

  66% {
    transform: translate3d(96vw, 14vh, 0);
  }

  100% {
    transform: translate3d(36vw, 28vh, 0);
  }
}

.dot:nth-child(24) {
  opacity: .5;
  width: 34px;
  height: 34px;
  background: radial-gradient(#fb515f, #fbec51 49%, #0000 51%);
  border: 2px solid #f7babf;
  animation: 46s .1s infinite alternate anim-24;
  transform: translate3d(21vw, 68vh, 0);
}

@keyframes anim-24 {
  33% {
    opacity: 0;
    transform: translate3d(89vw, 72vh, 0);
  }

  66% {
    transform: translate3d(90vw, 67vh, 0);
  }

  100% {
    transform: translate3d(40vw, 45vh, 0);
  }
}

.dot:nth-child(25) {
  opacity: .9;
  width: 22px;
  height: 22px;
  background: radial-gradient(#fb5181, #fbca51 49%, #0000 51%);
  border: 2px solid #f7bacb;
  animation: 38s .9s infinite alternate anim-25;
  transform: translate3d(24vw, 74vh, 0);
}

@keyframes anim-25 {
  33% {
    opacity: 0;
    transform: translate3d(27vw, 27vh, 0);
  }

  66% {
    transform: translate3d(54vw, 76vh, 0);
  }

  100% {
    transform: translate3d(61vw, 18vh, 0);
  }
}

.dot:nth-child(26) {
  opacity: .4;
  width: 28px;
  height: 28px;
  background: radial-gradient(#dbfb51, #51fb70 49%, #0000 51%);
  border: 2px solid #ecf7ba;
  animation: 31s .1s infinite alternate anim-26;
  transform: translate3d(13vw, 54vh, 0);
}

@keyframes anim-26 {
  33% {
    opacity: 0;
    transform: translate3d(6vw, 37vh, 0);
  }

  66% {
    transform: translate3d(25vw, 37vh, 0);
  }

  100% {
    transform: translate3d(91vw, 33vh, 0);
  }
}

.dot:nth-child(27) {
  opacity: .3;
  width: 21px;
  height: 21px;
  background: radial-gradient(#fb5165, #fbe751 49%, #0000 51%);
  border: 2px solid #f7bac1;
  animation: 25s .6s infinite alternate anim-27;
  transform: translate3d(13vw, 10vh, 0);
}

@keyframes anim-27 {
  33% {
    opacity: 0;
    transform: translate3d(10vw, 8vh, 0);
  }

  66% {
    transform: translate3d(83vw, 6vh, 0);
  }

  100% {
    transform: translate3d(12vw, 47vh, 0);
  }
}

.dot:nth-child(28) {
  opacity: .2;
  width: 9px;
  height: 9px;
  background: radial-gradient(#51a6fb, #a651fb 49%, #0000 51%);
  border: 2px solid #bad9f7;
  animation: 18s .5s infinite alternate anim-28;
  transform: translate3d(15vw, 87vh, 0);
}

@keyframes anim-28 {
  33% {
    opacity: 0;
    transform: translate3d(75vw, 65vh, 0);
  }

  66% {
    transform: translate3d(61vw, 95vh, 0);
  }

  100% {
    transform: translate3d(3vw, 64vh, 0);
  }
}

.dot:nth-child(29) {
  opacity: .9;
  width: 16px;
  height: 16px;
  background: radial-gradient(#fb51ca, #fb8151 49%, #0000 51%);
  border: 2px solid #f7bae6;
  animation: 46s .8s infinite alternate anim-29;
  transform: translate3d(50vw, 49vh, 0);
}

@keyframes anim-29 {
  33% {
    opacity: 0;
    transform: translate3d(71vw, 2vh, 0);
  }

  66% {
    transform: translate3d(25vw, 25vh, 0);
  }

  100% {
    transform: translate3d(20vw, 79vh, 0);
  }
}

.dot:nth-child(30) {
  opacity: .1;
  width: 36px;
  height: 36px;
  background: radial-gradient(#5195fb, #b751fb 49%, #0000 51%);
  border: 2px solid #bad3f7;
  animation: 45s .8s infinite alternate anim-30;
  transform: translate3d(95vw, 1vh, 0);
}

@keyframes anim-30 {
  33% {
    opacity: 0;
    transform: translate3d(35vw, 7vh, 0);
  }

  66% {
    transform: translate3d(98vw, 60vh, 0);
  }

  100% {
    transform: translate3d(63vw, 9vh, 0);
  }
}

.dot:nth-child(31) {
  opacity: .1;
  width: 32px;
  height: 32px;
  background: radial-gradient(#fb7651, #d6fb51 49%, #0000 51%);
  border: 2px solid #f7c7ba;
  animation: 48s .8s infinite alternate anim-31;
  transform: translate3d(8vw, 29vh, 0);
}

@keyframes anim-31 {
  33% {
    opacity: 0;
    transform: translate3d(4vw, 20vh, 0);
  }

  66% {
    transform: translate3d(30vw, 100vh, 0);
  }

  100% {
    transform: translate3d(60vw, 4vh, 0);
  }
}

.dot:nth-child(32) {
  opacity: .2;
  width: 7px;
  height: 7px;
  background: radial-gradient(#51e4fb, #6851fb 49%, #0000 51%);
  border: 2px solid #baeff7;
  animation: 28s .1s infinite alternate anim-32;
  transform: translate3d(40vw, 91vh, 0);
}

@keyframes anim-32 {
  33% {
    opacity: 0;
    transform: translate3d(94vw, 70vh, 0);
  }

  66% {
    transform: translate3d(81vw, 28vh, 0);
  }

  100% {
    transform: translate3d(88vw, 42vh, 0);
  }
}

.dot:nth-child(33) {
  opacity: .9;
  width: 24px;
  height: 24px;
  background: radial-gradient(#51fba6, #51a6fb 49%, #0000 51%);
  border: 2px solid #baf7d9;
  animation: 43s .5s infinite alternate anim-33;
  transform: translate3d(32vw, 99vh, 0);
}

@keyframes anim-33 {
  33% {
    opacity: 0;
    transform: translate3d(99vw, 72vh, 0);
  }

  66% {
    transform: translate3d(4vw, 40vh, 0);
  }

  100% {
    transform: translate3d(41vw, 4vh, 0);
  }
}

.dot:nth-child(34) {
  opacity: .6;
  width: 9px;
  height: 9px;
  background: radial-gradient(#81fb51, #51fbca 49%, #0000 51%);
  border: 2px solid #cbf7ba;
  animation: 34s 1s infinite alternate anim-34;
  transform: translate3d(61vw, 100vh, 0);
}

@keyframes anim-34 {
  33% {
    opacity: 0;
    transform: translate3d(80vw, 25vh, 0);
  }

  66% {
    transform: translate3d(8vw, 28vh, 0);
  }

  100% {
    transform: translate3d(34vw, 96vh, 0);
  }
}

.dot:nth-child(35) {
  opacity: .5;
  width: 27px;
  height: 27px;
  background: radial-gradient(#51abfb, #a051fb 49%, #0000 51%);
  border: 2px solid #badbf7;
  animation: 36s .6s infinite alternate anim-35;
  transform: translate3d(89vw, 38vh, 0);
}

@keyframes anim-35 {
  33% {
    opacity: 0;
    transform: translate3d(7vw, 32vh, 0);
  }

  66% {
    transform: translate3d(35vw, 64vh, 0);
  }

  100% {
    transform: translate3d(24vw, 67vh, 0);
  }
}

.dot:nth-child(36) {
  opacity: .4;
  width: 22px;
  height: 22px;
  background: radial-gradient(#fb51b7, #fb9551 49%, #0000 51%);
  border: 2px solid #f7badf;
  animation: 32s .9s infinite alternate anim-36;
  transform: translate3d(21vw, 52vh, 0);
}

@keyframes anim-36 {
  33% {
    opacity: 0;
    transform: translate3d(45vw, 50vh, 0);
  }

  66% {
    transform: translate3d(17vw, 82vh, 0);
  }

  100% {
    transform: translate3d(84vw, 65vh, 0);
  }
}

.dot:nth-child(37) {
  opacity: .8;
  width: 13px;
  height: 13px;
  background: radial-gradient(#fb5173, #fbd951 49%, #0000 51%);
  border: 2px solid #f7bac6;
  animation: 39s .4s infinite alternate anim-37;
  transform: translate3d(50vw, 71vh, 0);
}

@keyframes anim-37 {
  33% {
    opacity: 0;
    transform: translate3d(39vw, 97vh, 0);
  }

  66% {
    transform: translate3d(90vw, 59vh, 0);
  }

  100% {
    transform: translate3d(73vw, 38vh, 0);
  }
}

.dot:nth-child(38) {
  opacity: .7;
  width: 16px;
  height: 16px;
  background: radial-gradient(#51d0fb, #7b51fb 49%, #0000 51%);
  border: 2px solid #bae8f7;
  animation: 21s .3s infinite alternate anim-38;
  transform: translate3d(28vw, 45vh, 0);
}

@keyframes anim-38 {
  33% {
    opacity: 0;
    transform: translate3d(78vw, 72vh, 0);
  }

  66% {
    transform: translate3d(14vw, 76vh, 0);
  }

  100% {
    transform: translate3d(61vw, 32vh, 0);
  }
}

.dot:nth-child(39) {
  opacity: .5;
  width: 33px;
  height: 33px;
  background: radial-gradient(#5ffb51, #51fbec 49%, #0000 51%);
  border: 2px solid #bff7ba;
  animation: 38s .4s infinite alternate anim-39;
  transform: translate3d(23vw, 74vh, 0);
}

@keyframes anim-39 {
  33% {
    opacity: 0;
    transform: translate3d(32vw, 74vh, 0);
  }

  66% {
    transform: translate3d(85vw, 49vh, 0);
  }

  100% {
    transform: translate3d(70vw, 70vh, 0);
  }
}

.dot:nth-child(40) {
  opacity: .8;
  width: 25px;
  height: 25px;
  background: radial-gradient(#fb5168, #fbe451 49%, #0000 51%);
  border: 2px solid #f7bac2;
  animation: 42s .8s infinite alternate anim-40;
  transform: translate3d(87vw, 71vh, 0);
}

@keyframes anim-40 {
  33% {
    opacity: 0;
    transform: translate3d(28vw, 66vh, 0);
  }

  66% {
    transform: translate3d(61vw, 94vh, 0);
  }

  100% {
    transform: translate3d(84vw, 38vh, 0);
  }
}

.dot:nth-child(41) {
  opacity: .8;
  width: 40px;
  height: 40px;
  background: radial-gradient(#9dfb51, #51fbae 49%, #0000 51%);
  border: 2px solid #d6f7ba;
  animation: 16s .4s infinite alternate anim-41;
  transform: translate3d(78vw, 7vh, 0);
}

@keyframes anim-41 {
  33% {
    opacity: 0;
    transform: translate3d(97vw, 79vh, 0);
  }

  66% {
    transform: translate3d(10vw, 40vh, 0);
  }

  100% {
    transform: translate3d(38vw, 12vh, 0);
  }
}

.dot:nth-child(42) {
  opacity: .3;
  width: 22px;
  height: 22px;
  background: radial-gradient(#fb517b, #fbd051 49%, #0000 51%);
  border: 2px solid #f7bac9;
  animation: 24s 1s infinite alternate anim-42;
  transform: translate3d(66vw, 39vh, 0);
}

@keyframes anim-42 {
  33% {
    opacity: 0;
    transform: translate3d(19vw, 94vh, 0);
  }

  66% {
    transform: translate3d(95vw, 60vh, 0);
  }

  100% {
    transform: translate3d(79vw, 21vh, 0);
  }
}

.dot:nth-child(43) {
  opacity: .4;
  width: 25px;
  height: 25px;
  background: radial-gradient(#fb51db, #fb7051 49%, #0000 51%);
  border: 2px solid #f7baec;
  animation: 19s .1s infinite alternate anim-43;
  transform: translate3d(51vw, 15vh, 0);
}

@keyframes anim-43 {
  33% {
    opacity: 0;
    transform: translate3d(40vw, 100vh, 0);
  }

  66% {
    transform: translate3d(42vw, 71vh, 0);
  }

  100% {
    transform: translate3d(83vw, 18vh, 0);
  }
}

.dot:nth-child(44) {
  opacity: .9;
  width: 11px;
  height: 11px;
  background: radial-gradient(#51fb65, #51e7fb 49%, #0000 51%);
  border: 2px solid #baf7c1;
  animation: 44s .6s infinite alternate anim-44;
  transform: translate3d(34vw, 52vh, 0);
}

@keyframes anim-44 {
  33% {
    opacity: 0;
    transform: translate3d(11vw, 43vh, 0);
  }

  66% {
    transform: translate3d(4vw, 15vh, 0);
  }

  100% {
    transform: translate3d(98vw, 48vh, 0);
  }
}

.dot:nth-child(45) {
  opacity: .3;
  width: 8px;
  height: 8px;
  background: radial-gradient(#5157fb, #f551fb 49%, #0000 51%);
  border: 2px solid #babcf7;
  animation: 32s .8s infinite alternate anim-45;
  transform: translate3d(77vw, 86vh, 0);
}

@keyframes anim-45 {
  33% {
    opacity: 0;
    transform: translate3d(20vw, 66vh, 0);
  }

  66% {
    transform: translate3d(23vw, 77vh, 0);
  }

  100% {
    transform: translate3d(71vw, 96vh, 0);
  }
}

.dot:nth-child(46) {
  opacity: .2;
  width: 17px;
  height: 17px;
  background: radial-gradient(#de51fb, #fb516d 49%, #0000 51%);
  border: 2px solid #edbaf7;
  animation: 34s .6s infinite alternate anim-46;
  transform: translate3d(1vw, 35vh, 0);
}

@keyframes anim-46 {
  33% {
    opacity: 0;
    transform: translate3d(17vw, 93vh, 0);
  }

  66% {
    transform: translate3d(5vw, 90vh, 0);
  }

  100% {
    transform: translate3d(3vw, 10vh, 0);
  }
}

.dot:nth-child(47) {
  opacity: .3;
  width: 32px;
  height: 32px;
  background: radial-gradient(#5181fb, #ca51fb 49%, #0000 51%);
  border: 2px solid #bacbf7;
  animation: 43s .2s infinite alternate anim-47;
  transform: translate3d(37vw, 87vh, 0);
}

@keyframes anim-47 {
  33% {
    opacity: 0;
    transform: translate3d(99vw, 12vh, 0);
  }

  66% {
    transform: translate3d(3vw, 99vh, 0);
  }

  100% {
    transform: translate3d(71vw, 5vh, 0);
  }
}

.dot:nth-child(48) {
  opacity: .4;
  width: 1px;
  height: 1px;
  background: radial-gradient(#51bafb, #9251fb 49%, #0000 51%);
  border: 2px solid #bae0f7;
  animation: 46s .9s infinite alternate anim-48;
  transform: translate3d(20vw, 44vh, 0);
}

@keyframes anim-48 {
  33% {
    opacity: 0;
    transform: translate3d(17vw, 1vh, 0);
  }

  66% {
    transform: translate3d(81vw, 12vh, 0);
  }

  100% {
    transform: translate3d(11vw, 32vh, 0);
  }
}

.dot:nth-child(49) {
  opacity: .5;
  width: 17px;
  height: 17px;
  background: radial-gradient(#51bafb, #9251fb 49%, #0000 51%);
  border: 2px solid #bae0f7;
  animation: 44s .6s infinite alternate anim-49;
  transform: translate3d(83vw, 32vh, 0);
}

@keyframes anim-49 {
  33% {
    opacity: 0;
    transform: translate3d(55vw, 45vh, 0);
  }

  66% {
    transform: translate3d(24vw, 84vh, 0);
  }

  100% {
    transform: translate3d(29vw, 67vh, 0);
  }
}

.dot:nth-child(50) {
  opacity: .6;
  width: 4px;
  height: 4px;
  background: radial-gradient(#8c51fb, #fb51bf 49%, #0000 51%);
  border: 2px solid #d0baf7;
  animation: 37s 1s infinite alternate anim-50;
  transform: translate3d(63vw, 26vh, 0);
}

@keyframes anim-50 {
  33% {
    opacity: 0;
    transform: translate3d(57vw, 41vh, 0);
  }

  66% {
    transform: translate3d(14vw, 80vh, 0);
  }

  100% {
    transform: translate3d(30vw, 14vh, 0);
  }
}

/*# sourceMappingURL=index.0ebe6a70.css.map */
